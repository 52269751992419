import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";

function ConfirmFailed(props) {
  return (
    <div>
      <Card
        className="text-dark text-center p-3"
        style={{ minWidth: "840px", maxWidth: "840px" }}
      >
        <Row>
          <Col>
            <CardBody>
              <CardTitle tag="h2" className="m-3">
                Whoops!{" "}
                <span role="img" aria-label="upside down smile emoji">
                  🙃
                </span>
              </CardTitle>

              <CardText></CardText>

              <CardText className="my-3 pb-3">
                The password you entered doesn't match the one on the previous screen.
                <br></br>
                Try entering the password again, and remember to include the spaces{" "}
                <span role="img" aria-label="thumbs up emoji">
                  👍
                </span>
              </CardText>

              <Button
                size="lg"
                color="primary"
                onClick={() => props.goToStep(3)}
              >
                Try Again
              </Button>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default ConfirmFailed;
