import agileWords from "./AgileWords.json";

const getRandomNumber = (upperBoundary) => {
  const maxUInt32 = 0xffffffff;

  const a = new Uint32Array(1);
  crypto.getRandomValues(a);

  return Math.floor((a[0] / maxUInt32) * upperBoundary)
}

const generatePassword = (wordLength) => {
  const { words } = agileWords;
  const wordCount = words.length;
  
  let password = "";
  let counter = 0;

  while (counter < wordLength) { 
    const word = words[getRandomNumber(wordCount)];

    if (word.length > 4 && word.length < 8) { // only pick words that are between 5 and 7 characters
      password += word;
      if (counter !== wordLength) {
        password += " ";
      }

      counter++;
    }
  }

  return password.toLowerCase().trim();
};

export default generatePassword;
