import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Input,
  Container,
} from "reactstrap";

function ConfirmPassword(props) {
  const [password, setPassword] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (password === props.password) {
      props.goToStep(5);
    } else {
      setPassword("");
      props.goToStep(4);
    }
  };

  return (
    <div>
      <Card
        className="text-dark text-center p-3"
        style={{ minWidth: "840px", maxWidth: "840px" }}
      >
        <Row>
          <Col>
            <CardBody>
              <Button
                style={{ minWidth: "80px", maxWidth: "80px" }}
                color="primary"
                className="float-left"
                onClick={props.previousStep}
              >
                Back
              </Button>

              <form onSubmit={onFormSubmit}>
                <CardTitle tag="h2" className="m-3">
                  Confirm Your Monzo Password
                </CardTitle>

                <CardText>
                  Please type in your new Monzo password. (Don't forget the
                  spaces!)
                </CardText>

                <Input
                  className="text-monospace text-center my-5"
                  placeholder="confirm password"
                  type="password"
                  value={password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Container style={{ minWidth: "320px", maxWidth: "320px" }}>
                  <Button type="submit" block size="lg" color="primary">
                    Check
                  </Button>
                </Container>
              </form>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default ConfirmPassword;
