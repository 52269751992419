import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardImg,
  CardText,
  Container,
} from "reactstrap";

import hotChipBalloons from "./hotChipBalloons.png";

function Welcome(props) {
  return (
    <div>
      <Card
        className="text-dark p-3"
        style={{ minWidth: "840px", maxWidth: "840px" }}
      >
        <Row>
          <Col md="4">
            <CardImg
              src={hotChipBalloons}
              alt="Hot Chip Welcome"
              className="m-3"
              style={{ maxWidth: "512px" }}
            />
          </Col>

          <Col md="8">
            <CardTitle tag="h2" className="text-center my-4">
              Welcome to Monzo{" "}
              <span role="img" aria-label="Rocket emoji">
                🚀
              </span>
            </CardTitle>

            <CardBody>
              <CardText className="text-center my-3 pb-3">
                Before you get started, you're going to need a new password for
                everything at Monzo.
                <br />
                This guide will help you generate your new Monzo Password. So
                when you're ready, grab a pen and paper, and hit <b>Start</b>
              </CardText>
              <Container style={{ minWidth: "320px", maxWidth: "320px" }}>
                <Button
                  block
                  size="lg"
                  color="primary"
                  onClick={props.nextStep}
                >
                  Start
                </Button>
              </Container>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Welcome;
