import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
} from "reactstrap";

function Done(props) {
  return (
    <div>
      <Card
        className="text-dark text-center p-3"
        style={{ minWidth: "840px", maxWidth: "840px" }}
      >
        <Row>
          <Col>
            <CardBody>
              <CardTitle tag="h2" className="m-3">
                You Got It!{" "}
                <span role="img" aria-label="Clap emoji">
                  👏
                </span>
              </CardTitle>

              <CardText>
                This is going to be your Monzo Password for your time at Monzo.
              </CardText>

              <CardText className="font-weight-bold">
                Please don't use this password anywhere else outside of Monzo
                and please remember to destroy the written down version as soon
                as you have memorised it.
              </CardText>

              <CardText tag="h3">One last thing...</CardText>

              <CardText>
                Now all you need to do is change your temporary Google password
                to this new Monzo Password.
                <br />
                On the next page, you'll be asked for your temporary Google
                password first.
                <br />
                Log in with that, and then change your password to your new
                Monzo Password.
              </CardText>
              <CardText>When you're ready, click on the button below!</CardText>

              <Container style={{ minWidth: "320px", maxWidth: "320px" }}>
                <Button
                  type="a"
                  href="https://myaccount.google.com/signinoptions/password?continue=https%3A%2F%2Fmyaccount.google.com%2Fsigninoptions%2Ftwo-step-verification"
                  block
                  size="lg"
                  color="primary"
                >
                  Change Google Password
                </Button>
              </Container>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Done;
