import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Container,
} from "reactstrap";

import passwordGenerator from "../../lib/passwordGenerator";

const NewPassword = ({ refreshPassword, password, ...props }) => {
  const onChange = (event) => {
    event.preventDefault();
    refreshPassword(passwordGenerator(4));
  };

  return (
    <div>
      <Card
        className="text-dark text-center p-3"
        style={{ minWidth: "840px", maxWidth: "840px" }}
      >
        <Row>
          <Col>
            <CardBody>
              <CardTitle tag="h2" className="m-3">
                Your Monzo Password{" "}
                <span role="img" aria-label="Lock emoji">
                  🔐
                </span>
              </CardTitle>
              <h2 className="text-monospace mt-5 mb-5">{password}</h2>
              <Container className="text-left my-3 pb-3">
                <CardText>
                  This is your new Monzo Password! Your forever password. You'll
                  use this to log into your Monzo Google account.
                </CardText>

                <CardText>
                  To help commit this to memory, please write this down on your
                  secure piece of paper with a pen.
                  <br />
                  Keep this safe and hidden! When you feel confident you have
                  committed it to memory, destroy the paper!
                </CardText>
              </Container>
              <Container style={{ minWidth: "320px", maxWidth: "320px" }}>
                <Button
                  block
                  size="lg"
                  color="primary"
                  onClick={props.nextStep}
                >
                  Next
                </Button>
                <Button className="refresh-button" onClick={onChange}>
                  <ion-icon name="refresh"></ion-icon>
                </Button>
              </Container>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default NewPassword;
