import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import StepWizard from "react-step-wizard";
import "animate.css";

import Welcome from "./Welcome";
import NewPassword from "./NewPassword";
import ConfirmPassword from "./ConfirmPassword";
import ConfirmFailed from "./ConfirmFailed";
import Done from "./Done";

import passwordGenerator from "../lib/passwordGenerator";

import "./App.css";

const App = (props) => {
  const [password, setPassword] = useState("Generating ...");

  useEffect(() => {
    // Set password on first render
    setPassword(passwordGenerator(4));
  }, []);

  const customTransitions = {
    enterRight: "",
    enterLeft: "",
    exitRight: "",
    exitLeft: "",
  };

  return (
    <Container className="d-flex vh-100">
      <Row className="m-auto">
        <Col>
          <StepWizard transitions={customTransitions} className="mt-4 mb-4">
            <Welcome />
            <NewPassword
              {...props}
              refreshPassword={setPassword}
              password={password}
            />
            <ConfirmPassword
              {...props}
              refreshPassword={setPassword}
              password={password}
            />
            <ConfirmFailed />
            <Done />
          </StepWizard>
        </Col>
      </Row>
    </Container>
  );
};

export default App;
